export const table = {
  data () {
    return {
      printLen: 20,
      currentPage: 1,
      perPage: 8

    }
  },
  computed: {
    isPaginated () {
      return (this.data.data.length > this.perPage)
    },
    sortable: function () {
      if (!('sortable' in this.data)) {
        return true
      }
      return this.data.sortable
    },
    searchable: function () {
      if (!('searchable' in this.data)) {
        return true
      }
      return this.data.searchable
    },
    height () {
      let val = 0
      if (this.searchable) {
        val = 100
      }
      if (this.isPaginated) {
        val = val + 60
      }
      val = val + this.perPage * 29
      return val
    }
  },
  filters: {
    subStr: function (string) {
      if (string.length > 20) {
        return (string.substring(0, 20) + '...')
      } else {
        return (string)
      }
    }
  }
}
