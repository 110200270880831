<template>

  <b-table
    :data="data.data"
    :striped="true"
    :narrowed="true"
    :sortable="sortable"
    default-sort-direction="asc"
    :sticky-header="data.data.length>perPage"
    :debounce-search="300"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page="currentPage"
    :height="height"
  >
  <b-table-column
      v-for="(field,index) in data.fields"
      :key="index"
      :field="field.field"
      :label="field.labelShort?field.labelShort:field.label"
      :numeric="field.type==='numeric'"
      :sortable="sortable"
      :searchable="field.type!=='numeric'&&searchable"
    >
      <template v-slot:header="{ column }">
        <b-tooltip
          v-if="column.label!==field.label"
          :label="field.label" append-to-body dashed
        >{{ column.label }}</b-tooltip>
        <span v-else>{{ column.label }}</span>
      </template>
      <template v-slot="props">
        {{ props.row[field.field] }}
      </template>
    </b-table-column>
  </b-table>
</template>

<script>
import { table } from '@/mixins/table'
export default {
  name: 'BasicTable',
  props: {
    data: {
      type: Object,
      default: null
    },
    substrRowname: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: Boolean,
      default: true
    }
  },
  mixins: [table]
}
</script>

<style>
  .table thead th {
    border-bottom: 2px solid;
  }
  .b-table .table-wrapper + .level {
    background: #ffffff;
  }
  .pagination-link.is-current {
      background-color: #1896E1 !important;
      border-color: #1896E1 !important;
      color: #fff !important;
  }
</style>
